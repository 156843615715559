body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul.options {
  display: block;
  list-style: none;
  transition: width 0.3s;
  margin: auto;
  position: relative;
}

ul.options li {
  display: block;
  padding: 1px;
  font-size: 1.2rem;
}
ul.options li:hover {
  font-weight: bold;
  cursor: pointer;
  transition: 0.1s all;
}
.no-options {
  color: white;
}